import React, { useState } from 'react';
import Shuffle from 'shufflejs';

const whatWeDoFilters = [
  { filter: Shuffle.ALL_ITEMS, name: `All` },
  { filter: 'meals', name: `Meals` },
  { filter: 'donations', name: `Donations` },
  { filter: 'special', name: `Special Events` },
];

const Filter = (index, filter, name, activeFilter, setFilter, handleFilter) => {
  const isActive = (!activeFilter && index === 0) || (activeFilter && activeFilter === filter);
  return (
    <li 
      key={index}
      role="presentation"
      data-group={filter}
      className={isActive ? `filter-active` : ``}
      onClick={e => handleFilter(e, setFilter) }
      onKeyPress={e => { if (e.keyCode === 13) handleFilter(e, setFilter); } }
    >{ name }</li>
  );
};

const GalleryFilters = ({filterRef}) => {
  const handleFilter = (e, setFilter) => {
    e.preventDefault();

    const filterPkg = filterRef.current;
    const el = e.currentTarget;
    const group = el.getAttribute('data-group');
    setFilter(group);

    filterPkg.filter(group);

    //   // filterPkg.on('arrangeComplete', function() {
    //   //   window.AOS.refresh();
    //   // });
    // }
  };

  const [ activeFilter, setFilter ] = useState(Shuffle.ALL_ITEMS);

  return (
    <ul id="event-flters">
      {whatWeDoFilters.map((item, index) => Filter(index, item.filter, item.name, activeFilter, setFilter, handleFilter))}
    </ul>
  )
};

export default GalleryFilters;
