import React from 'react';
import { Email } from 'react-obfuscate-email';
import AnchorButton from '../anchor-button';

const Cta = ({type, value, children, ...props}) => {
  let result;
  if (type === 'button') {
    result = <AnchorButton value={value} {...props}>{children}</AnchorButton>;
  }
  else if (type === 'email') {
    // TODO: figure out a way not to ignore the children
    result = (<Email email={ value } {...props}></Email>);
  }
  else if (type === 'link') {
    result = (<a href={value} {...props}>{children}</a>);
  }
  else {
    result = (<></>);
  }

  return result;
};

export default Cta;
