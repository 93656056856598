import React from 'react';
import Layout from '../components/layout';
import HeroBanner from '../components/hero-banner';
import AboutSummary from '../components/about-summary';
import ImpactSummary from '../components/impact-summary';
import WhatSummary from '../components/what-summary';
import FAQSummary from '../components/faq-summary';
import ParticipateSummary from '../components/participate-summary';

const Home = () => {
  return (
    <Layout root={true} footerTheme="dark">
      <HeroBanner />
      <main id="main">
        <AboutSummary />
        <ImpactSummary theme="dark" />
        <ParticipateSummary />
        <WhatSummary theme="dark" />
        <FAQSummary />
      </main>
    </Layout>
  );
}

export default Home;
