import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { titles } from '../../state/constants/web-interface'; 

import aboutIllustration from '../../images/about-illustration.svg';

const aboutQuery = graphql`
  {
    ui: sanityUiInterface {
      about: aboutUI {
        description
        tagline
      }
    }
    services: sanityService {
      meals {
        title
        description
      }
      donations {
        title
        description
      }
    }
  }
`;

const AboutSummary = ({ theme }) => {
  const data = useStaticQuery(aboutQuery);

  return (
    <section id="about" className={`about ${(theme === 'dark' ? `section-bg` : ``)}`}>
      <div className="container">

        <div className="row justify-content-between">
          <div className="col-lg-6 d-flex align-items-center justify-content-center about-img">
            <img src={aboutIllustration} className="img-fluid" alt={titles.aboutTitle} data-aos="zoom-in" />
          </div>
          <div className="col-lg-6 pt-5 pt-lg-0">
            <h3 data-aos="fade-up">{ data.ui.about.tagline }</h3>
            <p data-aos="fade-up" data-aos-delay="100">{ data.ui.about.description }</p>
            <div className="row">
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                <i className="yrl yrl-bx-dish"></i>
                <h4>{ data.services.meals.title }</h4>
                <p>{ data.services.meals.description }</p>
              </div>
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="100">
                <i className="yrl yrl-bx-donate-heart"></i>
                <h4>{ data.services.donations.title }</h4>
                <p>{ data.services.donations.description }</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default AboutSummary;
