import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import { titles } from '../../state/constants/web-interface'; 

const faqQuery = graphql`
  {
    ui: sanityUiInterface {
      faq: faqUI {
        tagline
      }
    }
    faqs: allSanityFaq {
      nodes {
        question
        answer: _rawAnswer
      }
    }
  }
`;

const QuestionAnswer = (faq, index) => (
  <li key={index}>
    <div data-bs-toggle="collapse" className="collapsed question" href={`#faq${index + 1}`}>{ faq.question } <i className="yrl yrl-chevron-down icon-show"></i><i className="yrl yrl-chevron-up icon-close"></i></div>
    <div id={`faq${index + 1}`} className="collapse" data-bs-parent=".faq-list">
      <BlockContent blocks={faq.answer} />
    </div>
  </li>
);

const FAQSummary = ({ theme }) => {
  const data = useStaticQuery(faqQuery);

  return (
    <section id="faq" className={`faq ${(theme === 'dark' ? `section-bg` : ``)}`}>
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>{ titles.faqTitle }</h2>
          <p>{ data.ui.faq.tagline }</p>
        </div>

        <ul className="faq-list" data-aos="fade-up" data-aos-delay="100">
          { data.faqs.nodes.map(QuestionAnswer) }
        </ul>

      </div>
    </section>
  );
};

export default FAQSummary;
