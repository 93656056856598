import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Cta from '../cta';
import Testmonials from '../testimonials';
import LazyImage from '../lazy-image';

import { titles } from '../../state/constants/web-interface';
import { participateIconTransform } from '../../utils/cloudinary';

const participateQuery = graphql`
  {
    ui: sanityUiInterface {
      participate: participateUI {
        tagline
      }
    }

    testimonials: allSanityTestimonial(sort: {fields: _createdAt, order: DESC}) {
      nodes {
        author
        position
        description: testimonial
        photo {
          secure_url
        }
      }
    }

    participate: sanityParticipate {
      volunteer {
        title
        icon {
          secure_url
        }
        description
        cta {
          value
          type
          text
          target {
            email
          }
        }
      }
      moneyDonation {
        title
        icon {
          secure_url
        }
        description
        cta {
          value
          type
          text
          target {
            email
          }
        }
      }
      itemsDonation {
        title
        icon {
          secure_url
        }
        description
        cta {
          value
          type
          text
          target {
            email
          }
        }
      }
      id
    }  
  }
`;


const ParticipateSummary = ({ theme }) => {
  const data = useStaticQuery(participateQuery);

  const volunteer = data.participate.volunteer;
  const itemsDonation = data.participate.itemsDonation;
  const moneyDonation = data.participate.moneyDonation;

  const volunteerIcon = participateIconTransform(volunteer.icon.secure_url);
  const itemsDonationIcon = participateIconTransform(itemsDonation.icon.secure_url);
  const moneyDonationIcon = participateIconTransform(moneyDonation.icon.secure_url);

  return (
    <section id="participate" className={`contact ${(theme === 'dark' ? `section-bg` : ``)}`}>
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>{ titles.participateTitle }</h2>
          <p>{ data.ui.participate.tagline }</p>
        </div>

        <div className="row">
          <Testmonials items={data.testimonials.nodes} />

          <div className="col-lg-8 pt-5 pt-lg-0 make-a-difference" data-aos="fade-up">
            <div className="row">
              <div className="col-md-6 col-lg-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box">
                  <LazyImage ratio="fourxthree" width="64" height="48" src={volunteerIcon} alt="volunteer icon"/>
                  <h4 className="title">{ volunteer.title }</h4>
                  <p className="description">{ volunteer.description }</p>
                  <div className={volunteer.cta.type !== 'button' ? `card-link` : ``}>
                    <Cta 
                      type={volunteer.cta.type}
                      value={(
                        volunteer.cta.type === 'email' ? volunteer.cta.target.email : volunteer.cta.value
                      )}>{volunteer.cta.type !== 'email' ? volunteer.cta.text : ``}</Cta>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box">
                  <LazyImage ratio="fourxthree" width="64" height="48" src={itemsDonationIcon} alt="items donation icon" />
                  <h4 className="title">{ itemsDonation.title }</h4>
                  <p>{ itemsDonation.description }</p>
                  <div className={itemsDonation.cta.type !== 'button' ? `card-link` : ``}>
                    <Cta
                      type={itemsDonation.cta.type}
                      value={(
                        itemsDonation.cta.type === 'email' ? itemsDonation.cta.target.email : itemsDonation.cta.value
                      )}>{itemsDonation.cta.type !== 'email' ? itemsDonation.cta.text : ``}</Cta>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box">
                  <LazyImage ratio="fourxthree" width="64" height="48" src={moneyDonationIcon} alt="donations icon" />
                  <h4 className="title">{moneyDonation.title}</h4>
                  <p className="description">{moneyDonation.description}</p>
                  <div className={moneyDonation.cta.type !== 'button' ? `card-link` : ``}>
                    <Cta
                      type={moneyDonation.cta.type}
                      value={(
                        moneyDonation.cta.type === 'email' ? moneyDonation.cta.target.email : moneyDonation.cta.value
                        )}>{moneyDonation.cta.type !== 'email' ? moneyDonation.cta.text : ``}</Cta>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </section>
  );
};

export default ParticipateSummary;
