import React, { useEffect, useRef, useState } from 'react';
import { useReadyStateEffect } from 'react-ready-state-effect';

import { Link, useStaticQuery, graphql } from 'gatsby';

import Shuffle from 'shufflejs';

import LazyImage from '../lazy-image';
import GalleryFilters from '../gallery-filters';

import { titles } from '../../state/constants/web-interface';
import { eventThumbTransform, eventTransform } from '../../utils/cloudinary';

export const eventsFragment = graphql`
  fragment eventsFragment on SanityEvent {
    type
    title
    photos {
      secure_url
      context {
        custom {
          alt
        }
      }
    }
    location
    description: _rawDescription
    date
    id
    slug
  }
`;

const eventsQuery = graphql`
  {
    ui: sanityUiInterface {
      whatwedo: whatWeDoUI {
        tagline
      }
    }
    
    latest6Events: allSanityEvent(limit: 6, sort: {fields: date, order: DESC}) {
      nodes { ...eventsFragment }
    }

    latestByTypeEvents: allSanityEvent(limit: 3, sort: {fields: date, order: DESC}) {
      nodes { ...eventsFragment }
      distinct(field: type)
    }
  }
`;

const latestWithAtLeastOneOfType = (data, limit) => {
  const uniqueByTypeIds = data.latestByTypeEvents.nodes.map(node => node.id);
  data.latest6Events.nodes.forEach(node => {
    const index = uniqueByTypeIds.indexOf(node.id);
    if (index >= 0) {
      uniqueByTypeIds.splice(index, 1);
      data.latestByTypeEvents.nodes.splice(index, 1);
    }
  });
  return data.latest6Events.nodes.concat(data.latestByTypeEvents.nodes).slice(0, 6);
};

const Event = (event, index) => {
  const photo = event.photos[0];
  const thumbPhoto = eventThumbTransform(photo.secure_url);
  const regularPhoto = eventTransform(photo.secure_url);

  return (
    <div key={index} className={`col-lg-4 col-md-6 event-item filter-${ event.type }`} data-groups={`["${ event.type }"]`}>
      <div className="event-wrap">
        <LazyImage ratio="fourxthree" src={thumbPhoto} className="img-fluid" width="416" height="312" alt={photo.context?.custom?.alt} />
        <div className="event-links">
          <a
            href={regularPhoto}
            data-gallery="eventGallery"
            className="event-lightbox"
            title={event.title}
            aria-label={event.title}
          ><i className="yrl yrl-plus"></i></a>
          <Link
            to={`events/${event.slug}`}
            state={{ event: event }}
            title="More Details"
            aria-label="more details"
          ><i className="yrl yrl-link"></i></Link>
        </div>
        <div className="event-info">
          <h4>{ event.title }</h4>
          <p>{ event.date }</p>
        </div>
      </div>
    </div>
  );
};

const WhatSummary = ({ theme }) => {
  const [ filterPkgLoaded, setFilterPkgFlag] = useState(false);
  const filterRef = useRef();

  const prepareGallery = () => {    
    const container = document.querySelector('.event-container');
    filterRef.current = new Shuffle(container, {
      itemSelector: '.event-item',
    });

    setFilterPkgFlag(true);
  };

  useEffect(() => {
    const GLightbox = require('glightbox/dist/js/glightbox');
    GLightbox({
      selector: '.event-lightbox'
    });
  }, []);

  useReadyStateEffect(() => {
    prepareGallery();
  }, [], 'complete');

  const data = useStaticQuery(eventsQuery);
  const events = latestWithAtLeastOneOfType(data, 6);

  return (
    <section id="what-we-do" className={`events ${(theme === 'dark' ? `section-bg` : ``)}`}>
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>{ titles.whatWeDoTitle }</h2>
          <p>{ data.ui.whatwedo.tagline }</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12">
            { filterPkgLoaded && 
              <GalleryFilters filterRef={filterRef} />
            }
          </div>
        </div>

        <div className="row event-container" data-aos="fade-up" data-aos-delay="200">
          {events.map(Event)}
        </div>

      </div>
    </section>
  );
};

export default WhatSummary;
