import React from 'react';
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import { profileTransform } from '../../utils/cloudinary';
import LazyImage from '../lazy-image';

const Testimonial = (testimonial, index) => {
  const photo = profileTransform(testimonial.photo.secure_url);
  return (
    <SwiperSlide key={index}>
      <div className="testimonial-item">
        <LazyImage ratio="onexone" src={photo} className="testimonial-img" width="148" height="148" alt={`${testimonial.author} profile`} />
        <h3>{ testimonial.author }</h3>
        <h4>{ testimonial.position }</h4>
        <p>
          <i className="yrl yrl-bxs-quote-alt-left quote-icon-left"></i>
          { testimonial.description }
          <i className="yrl yrl-bxs-quote-alt-right quote-icon-right"></i>
        </p>
      </div>
    </SwiperSlide>
  );
};

const TestimonialsSwiper = ({ testimonials }) => {
  return (
    <Swiper
      className="testimonials-slider swiper-container"
      modules={[Pagination, A11y]}
      loop={true}
      spaceBetween={0}
      pagination={{ type: 'bullets', clickable: true }}
    >
      {testimonials.map(Testimonial)}
    </Swiper>
  );
};

const Testimonials = ({items}) => {
  return (
    <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
      <div className="testimonials">
        <div className="container position-relative">
          <TestimonialsSwiper testimonials={items} />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
