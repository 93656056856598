import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Cta from '../cta';
import { transform } from '../../utils/cloudinary';

const bannerQuery = graphql`
  {
    ui: sanityUiInterface {
      heroBanner {
        missionStatement
        visionStatement
        image {
          secure_url
          context {
            custom {
              alt
            }
          }
        }
        cta {
          value
          type
          text
          target {
            email
          }
        }
      }
    }
  }
`;

const HeroBanner = () => {
  const data = useStaticQuery(bannerQuery);
  // TODO: find a way to get the alt for the photo
  const photo = data.ui.heroBanner.image;
  const photoURL = transform(photo.secure_url)
    .width('636')
    .height('460')
    .quality('auto:good')
    .format('auto')
    .toString();

  return (
    <section id="hero" className="d-flex align-items-center">

      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1>{ data.ui.heroBanner.missionStatement }</h1>
            <h2>{ data.ui.heroBanner.visionStatement }</h2>
            <div>
            <Cta
              type={data.ui.heroBanner.cta.type}
              value={(
                data.ui.heroBanner.cta.type === 'email' ? data.ui.heroBanner.cta.target.email : data.ui.heroBanner.cta.value
              )}>{data.ui.heroBanner.cta.type !== 'email' ? data.ui.heroBanner.cta.text : ``}</Cta>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img">
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img src={ photoURL } className="animated" alt={photo.context?.custom?.alt} />
          </div>
        </div>
      </div>

    </section>
  );
};

export default HeroBanner;

