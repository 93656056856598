import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { titles } from '../../state/constants/web-interface'; 

const impactQuery = graphql`
  {
    ui: sanityUiInterface {
      impact: impactUI {
        tagline
      }
    }
    facts: sanityImpact {
      impact1 {
        number
        title
        description
      }
      impact2 {
        number
        title
        description
      }
      impact3 {
        number
        title
        description
      }
      impact4 {
        number
        title
        description
      }
    }
  }
`;

const Impact = (item, index) => {
  const aosDelay = (index + 1) * 100;
  return (
    <div key={index} className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={aosDelay}>
      <div className="icon-box">
        <p className="as-h3">{ item.number }</p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <h4 className="title"><a href="">{ item.title }</a></h4>
        <p className="description">{ item.description }</p>
      </div>
    </div>
  );
};

const ImpactSummary = ({ theme }) => {
  const data = useStaticQuery(impactQuery);
  const facts = [];
  Object.keys(data.facts).forEach(key => facts.push(data.facts[key]));

  return (
    <section id="impact" className={`services ${(theme === 'dark' ? `section-bg` : ``)}`}>
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>{ titles.impactTitle }</h2>
          <p>{ data.ui.impact.tagline }</p>
        </div>

        <div className="row">
          {facts.map(Impact)}
        </div>

      </div>
    </section>
  );
};

export default ImpactSummary;
