import React from 'react';

const ratios = {
  fourxthree: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAxJREFUeJxjYCAZAAAAMwABh16cVQAAAABJRU5ErkJggg==`,
  onexone: `data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=`
};

const LazyImage = ({ratio, ...props}) => {
  const tempSource = ratios[ratio];
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img loading="lazy"  {...props}  data-src={props.src} src={tempSource} />
    </>
  );
};

export default LazyImage;
